<template>
  <div class="MarkingReappraisal">
    <refreshTime ref="refreshTime"></refreshTime>
    <div class="tips">
      <img src="@/assets/uExam/icon_waring.png" alt="" />
      注意：1、重评后将删除所选内容的已评信息；2、绑定批阅的试题也将一同被重评。
    </div>

    <div class="search-box">
      <div>
        <template v-if="scoringMode == 2">
          分组：
          <el-select
            v-model="groupId"
            placeholder="请选择分组"
            filterable
            @change="checkSchoolGroup()"
          >
            <el-option
              v-for="item in groupList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
            <el-option label="全部分组" value="-1"> </el-option>
          </el-select>
        </template>

        学校筛选：
        <el-select
          v-model="schoolId"
          filterable
          placeholder="请选择学校"
          @change="checkSchool()"
        >
          <el-option
            v-for="item in schoolList"
            :key="item.schoolId"
            :label="item.schoolName"
            :value="item.schoolId"
          >
          </el-option>
          <el-option label="全部学校" value="-1"> </el-option>
        </el-select>
        题目：
        <el-select
          v-model="webId"
          placeholder="请选择题目"
          @change="checkQestion"
        >
          <!-- <template> -->
          <el-option
            v-for="(item, i) in questionOrderList"
            :key="i"
            :disabled="showItem(item)"
            :label="item.label"
            :value="item.webId"
          >
          </el-option>
          <!-- </template> -->
        </el-select>

        阅卷老师：
        <el-select v-model="teacherId" placeholder="请选择老师">
          <el-option
            v-for="item in teacherList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button
          class="set-btn"
          plain
          icon="el-icon-setting"
          type="primary"
          @click="showModel()"
          >重评</el-button
        >
      </div>
    </div>

    <el-dialog title="温馨提示" :visible.sync="dialogVisible" width="30%">
      <div v-if="!indexTeacher.teacherId" class="model-tips">
        此操作将重评
        <span>{{ getName(schoolId, paperList) }}</span>
        <span>{{ examPaperId | setName(paperList) }}</span>
        <span>{{ "主观题" + nowQuestion.label }}</span>
        <span>{{ teacherId | setName(teacherList) }}</span>
        的全部已阅试题，确认重评请输入登录密码，否则点击取消;
      </div>
      <div v-else class="model-tips">
        此操作将重评
        <span>{{ indexTeacher.schoolName }}</span>
        的全部已阅试题，确认重评请输入登录密码，否则点击取消;
      </div>
      <div class="password">
        请输入登录密码：
        <a-input-password
          v-model="password"
          placeholder=""
          autocomplete="new-password"
          @keyup.enter.native="monitorBack"
        />
        <!-- <el-input
          v-model="password"
          autocomplete="new-password"
          placeholder="请输入登录密码"
          show-password
        ></el-input> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="monitorBack()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getObj, schoollistbyexamid } from "@/core/api/exam/exam";
import {
  getQuestionTeacher,
  monitorBack2,
  questionTeacher,
  // markabnormolStudentInfo,
} from "@/core/api/exam/paperMonitor";
import refreshTime from "./components/refreshTime";
import { encryption, showSubject } from "@/core/util/util";
// import { getStore } from "@/core/util/store";
import { mapGetters } from "vuex";
import { schoolGroupList } from "@/core/api/exam/examCorrectTask";
export default {
  name: "MarkingReappraisal",
  components: { refreshTime },
  data() {
    return {
      groupId: "",
      password: "",
      dialogVisible: false,
      btnLoading: false,
      examPaperId: "",
      scoringMode: "",
      questionNum: "",
      nowQuestion: {},
      teacherId: "",
      groupList: [],
      paperList: [],
      teacherList: [],
      questionOrderList: [],
      indexTeacher: {},
      classList: [],

      indexView: 1,
      schoolList: [],
      schoolId: "",
      webId: "",
    };
  },
  computed: {
    ...mapGetters(["GET_SCHOOL_LIST"]),
  },

  created() {
    this.examPaperId = Number(this.$route.query.examPaperId);
    this.getQuestionTeacher(1);
    this.getQuestionTeacher(2);
    this.getExamInfo();
    // else {
    this.getSchoollistbyexamid();
    // }
  },
  methods: {
    showModel() {
      if (!this.groupId && this.scoringMode == 2) {
        this.$message({
          message: "请选择分组",
          type: "warning",
        });
        return;
      }
      if (!this.schoolId) {
        this.$message({
          message: "请选择学校",
          type: "warning",
        });
        return;
      }
      if (this.webId === "") {
        this.$message({
          message: "请选择题目",
          type: "warning",
        });
        return;
      }
      if (this.teacherId === "") {
        this.$message({
          message: "请选择阅卷教师",
          type: "warning",
        });
        return;
      }
      this.dialogVisible = true;
      this.indexTeacher = {};
      this.password = "";
    },
    getNewItem() {
      this.getQuestionTeacher(1);
      this.getQuestionTeacher(2);
    },
    getName() {
      let str = "";
      this.schoolList.map((item) => {
        if (item.schoolId == this.schoolId) {
          str = item.schoolName;
        }
      });
      if (str == "") {
        str = "全部学校";
      }
      return str;
    },
    checkSchoolGroup() {
      this.schoolId = "";
      this.getSchoollistbyexamid();
    },
    async getSchoollistbyexamid() {
      const res = await schoollistbyexamid({
        examId: this.$route.query.examId,
        groupId: this.groupId == -1 ? "" : this.groupId,
      });

      this.schoolList = res.data.data;
    },
    showItem(v) {
      let type = false;
      if (v.bindQuestionNum) {
        //
        let arr = v.bindQuestionNum.split(",");
        if (arr.indexOf(v.value) != 0) {
          type = true;
        }
      }
      if (v.isChild) {
        type = true;
      }

      return type;
    },
    showDialogVisible(item) {
      this.indexTeacher = Object.assign({}, item);
      this.password = "";
      this.dialogVisible = true;
    },
    showSubject(v) {
      return showSubject(v, 8);
    },
    checkSubject() {
      this.getQuestionTeacher(1);
      // this.getQuestionTeacher(2);
    },
    checkQestion() {
      this.nowQuestion = this.questionOrderList.filter(
        (item) => item.webId == this.webId
      )[0];
      this.teacherId = "";
      if (this.webId == -1) {
        this.teacherList = [{ label: "所有老师", value: "-1" }];
        return;
      }

      this.questionTeacher();
    },
    checkSchool() {
      this.teacherId = "";
      if (this.webId == -1) {
        this.teacherList = [{ label: "所有老师", value: "-1" }];
        return;
      }
      this.questionTeacher();
    },
    questionTeacher() {
      let data = {
        examPaperId: this.examPaperId,
        questionNum: this.nowQuestion.value,
        schoolId: this.schoolId,
        sheetDetailId: this.nowQuestion.sheetDetailId,
      };
      questionTeacher(data).then((res) => {
        this.teacherList = [{ label: "所有老师", value: "-1" }];
        // console.log(res);
        res.data.data.forEach((item) => {
          this.teacherList.push({
            label: item.name,
            value: item.id,
          });
        });
        // this.teacherId = "-1";
      });
    },
    getQuestionTeacher(type) {
      let data = {
        examPaperId: this.examPaperId,
        type: type,
      };
      getQuestionTeacher(data).then((res) => {
        if (type == 1) {
          this.questionOrderList = res.data.data.map((item, index) => {
            item.webId = index;
            return item;
          });
          this.questionOrderList.push({
            label: "全部",
            webId: -1,
          });
          if (this.questionOrderList.length > 0) {
            this.questionNum = this.questionOrderList[0].value;
            this.nowQuestion = this.questionOrderList[0];
            this.questionTeacher();
          }
        }
        // if (type == 2) {
        //
        //   let Array = [];
        //   res.data.data.forEach((item) => {
        //     if (Array.indexOf(item.value) == -1) {
        //       Array.push(item.value);
        //       this.teacherList.push(item);
        //     }
        //   });
        //   this.teacherId = "-1";
        // }
      });
    },
    monitorBack() {
      if (!this.password) {
        this.$message({
          message: "请输入登录密码",
          type: "warning",
        });
        return;
      }

      let userInfo = {
        randomStr: "blockPuzzle",
        code: "xxx",
        password: this.password,
      };
      const user = encryption({
        data: userInfo,
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });
      let data = {
        examPaperId: this.examPaperId,
        questionNum: this.nowQuestion.value,
        password: user.password,
        teacherId: this.teacherId,
        type: this.$route.query.examType,
        sheetDetailId: this.nowQuestion.sheetDetailId,
        isPoint: this.nowQuestion.type,
        schoolId: this.schoolId,
        groupId: this.groupId,
      };
      if (this.webId == -1) {
        data.ifBackAll = true;
        data.sheetDetailId = "";
        data.isPoint = 0;
      }
      let subjectId = this.paperList.filter(
        (item) => item.value == this.examPaperId
      )[0].subjectId;
      data.subjectId = subjectId;
      this.btnLoading = true;
      monitorBack2(data)
        .then(() => {
          this.$message({
            message: "重评成功！",
            type: "success",
          });
          this.dialogVisible = false;
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    //联考-获取学校分组列表
    async getschoolGroupList() {
      let data = {
        examId: this.$route.query.examId,
        examPaperId: this.$route.query.examPaperId,
      };
      await schoolGroupList(data)
        .then((res) => {
          this.groupList = res.data.data;
        })
        .catch(() => {});
    },
    getExamInfo() {
      getObj(this.$route.query.examId).then((res) => {
        this.examInformation = res.data.data;
        res.data.data.paperList.map((item) => {
          if (this.examPaperId == item.paperId) {
            this.scoringMode = item.scoringMode;
            if (this.scoringMode == 2) {
              this.getschoolGroupList();
            }
          }
          this.paperList.push({
            value: item.paperId,
            label: item.subjectName,
            subjectId: item.subjectId,
          });
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.MarkingReappraisal {
  padding: 24px;
  .ant-input-affix-wrapper {
    width: auto;
  }
  .el-table {
    margin-top: 24px;
  }
  .list-tips {
    margin-top: 24px;
    p {
      padding-left: 21px;
      &:first-child {
        padding-left: 0;
      }
    }
  }
  .header-box {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #dee1e3;
    padding-bottom: 24px;
    .go-back {
      cursor: pointer;
    }
    .title {
      color: #d92020;
    }
  }
  .model-tips {
    span {
      color: #d92020;
      margin-right: 4px;
      &:first-child {
        margin-left: 4px;
      }
    }
  }
  .password {
    margin-top: 12px;
    // text-align: center;
    .el-input {
      width: 150px;
    }
  }
  .tips {
    background: #fff3f3;
    border-radius: 4px;
    color: #777473;
    padding: 10px 12px;
    margin-bottom: 18px;
  }
  .search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .el-select {
      margin: 0 24px 0 8px;
    }
    .subject-name {
      width: 120px;
    }
  }
}
</style>
